.content-block {
    margin-top: 50px;
    border: 1px dashed #e9e9e9;
    border-radius: 6px;
    background-color: #fafafa;
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px;
}

.validate {
    margin-bottom: 30px;
    width:300px;
}
.asdmk {
    margin: 10px;
    width: 300px;
}
.asdmk *{
    line-height: normal;
    vertical-align: middle;
    text-align: center;
}
.asdmk span{
    margin: 0 auto;
}


.facebook-button-class{
    margin: 10px;
    width: 300px;
    background-color: #4267b2;
    display: inline-flex;
    align-items: center;
    color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
    padding: 0px;
    border-radius: 2px;
    border: 1px solid transparent;
    font-size: 14px;
    font-weight: 500;
    font-family: Helvetica, Arial, sans-serif;
}

.facebook-button-class .text{
    margin: 0 auto;
    padding: 10px 10px 10px 0px; font-weight: 500;
}

.facebook-button-class *{
    line-height: normal;
    vertical-align: middle;
    text-align: center;

}
.facebook-button-class i{
    font-size: 38px;
}

.facebook-button-class div{
    margin-right: 10px; background: rgb(255, 255, 255); padding: 10px; border-radius: 2px;
}
