.steps-line {
    padding: 0 25px;
    max-width: 1280px;
    margin: 0 auto;
}

.content-block {
    margin: 50px auto;
    max-width: 1400px;
    /*margin-top: 50px;*/
    border: 1px dashed #e9e9e9;
    border-radius: 6px;
    background-color: #fafafa;
    min-height: 200px;
    text-align: center;
    /*padding: 50px;*/
}

.steps-action {
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
}

.step-main {
    margin: 50px;
    /*margin: 50px auto;*/
}
.upload-main {
    margin: 0 auto;
    /*margin: 50px auto;*/
}

.upload-button-text {
    margin-top: 8px;
    color: #FF9900;
}

.foo {
    display: none;
    opacity: 0;
    animation: fade-out 1s 1;
    min-width:720px;
    margin:0 auto;
}

.foo.fade-in {
    display: block;
    opacity: 1;
    animation: fade-in 0.5s 1;
}

/*.text-field {*/
/*    background-color:#fff;*/
/*    min-height:200px;*/
/*}*/
.text-field > *{
    background-color:#fff;
    border:1px solid #d9d9d9;
    border-radius: 4px;
    min-height:200px;
    width:100%;
}
.text-field > *,
.text-field > * > *,
.text-field > * > * > *,
.text-field > * > * > * > *,
.text-field > * > * > * > * > * {
    width:100%;
}
