.queue-block {
    margin-top: 50px;
    border: 1px dashed #e9e9e9;
    border-radius: 6px;
    background-color: #fafafa;
    min-height: 200px;
    align-items: center;
    justify-content: center;
    padding: 50px;
}
