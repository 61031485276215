.card-color-intro {
    background-image: linear-gradient(to bottom left, #FFD700, #FF9900);
    text-align: center;
    color: white;
    padding: 100px 0;
}

.card-color-intro #title {
    font-size: 5em;
    font-weight: bolder;
    margin-bottom: 15px;
}

.card-color-intro #content {
    font-size: 2em;
    margin: 50px;
}

.card-default {
    background-color: white;
    text-align: center;
    color: black;
    padding: 100px 0;
}

.card-default #title {
    font-size: 3em;
    font-weight: bold;
    margin-bottom: 20px;
}

.card-default #content {
    font-size: 1.25em;
    margin: 20px 50px;
}
